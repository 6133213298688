import { useNavigate } from 'react-router-dom'
import { EmbedPayNowEligibilityFailureCode } from '@genoa/domain'
import { GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'
import { ordinalSuffixFor } from '@genoa/utils'
import { DateTime } from 'luxon'

import { useLazyEmbedPayNowEligibilityQuery } from '../../modules/flexApi'
import * as Routes from '../../routing/constants'

export const useEmbedBlockScreens = () => {
  const navigate = useNavigate()
  const [embedPayNowEligibilityQuery, { data: embedPayNowEligibility, error }] = useLazyEmbedPayNowEligibilityQuery()

  useQueryError(error, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        navigate(Routes.Embed.CONGRATS, { state: { errorCode: GeneralApiErrorCodes.RATE_LIMIT } })
        return true
      }
    },
    onAllErrors() {
      navigate(Routes.Embed.CONGRATS, { state: { errorCode: 'default' } })
    },
  })

  function checkBlockingStates() {
    if (!embedPayNowEligibility?.data.eligible) {
      switch (embedPayNowEligibility?.data.failure_code) {
        case EmbedPayNowEligibilityFailureCode.AUTOPAY_ON:
          navigate(Routes.Embed.ALREADY_FLEXING_YOUR_RENT, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.BP_WINDOW:
          const bpMonth = DateTime.fromFormat(embedPayNowEligibility.data.bp_end_date, 'yyyy-MM-dd').monthLong
          const bpDate = DateTime.fromFormat(embedPayNowEligibility.data.bp_start_date, 'yyyy-MM-dd')
          const bpStartDate = `${bpDate.monthLong} ${ordinalSuffixFor(bpDate.day)}`
          navigate(Routes.Embed.NOT_RENT_DAY, {
            replace: true,
            state: {
              bpMonth: bpMonth,
              bpStartDate: bpStartDate,
            },
          })
          break
        case EmbedPayNowEligibilityFailureCode.RELINK_PROPERTY:
          navigate(Routes.Embed.RELINK_DIRECT_INTEGRATION_CONFIRMATION)
          break
        case EmbedPayNowEligibilityFailureCode.RENT_PAYMENT_IN_PROGRESS:
        case EmbedPayNowEligibilityFailureCode.RENT_PAID:
          navigate(Routes.Embed.RENT_SPLIT_PROGRESS, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.INVALID_PRODUCT:
          navigate(Routes.Embed.BUILD_CREDIT_IN_APP, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.INVALID_BALANCE:
          navigate(Routes.Embed.SERVICE_ISSUE, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.UNKNOWN:
          navigate(Routes.Embed.SOMETHING_WENT_WRONG, { replace: true })
          break
        default:
          navigate(Routes.Embed.SOMETHING_WENT_WRONG)
          break
      }
    }
  }

  return { checkBlockingStates, embedPayNowEligibility, embedPayNowEligibilityQuery }
}
