export const retainHashedEmail = (originalData?: any, cleanedData?: any) => {
  if (originalData === undefined || !originalData.hasOwnProperty('email')) {
    return cleanedData
  }
  // original data has the email key; check to see if the value is hashed or not
  const originalEmail = originalData.email.toString()
  if (originalEmail.indexOf('@') === -1) {
    // a hashed email address would not contain the @ symbol, we can safely add the hashed email back and return
    return {
      ...cleanedData,
      email: originalEmail,
    }
  }
  // we don't want to retain plaintext email addresses, as detected by the presence of the @ symbol
  // return the cleaned data as that would have already stripped away the email key! (clean-log-data.ts)
  return cleanedData
}
