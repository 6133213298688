import { useCallback, useEffect, useMemo, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { GeneralApiErrorCodes, useQueryError, WalletErrorCode } from '@genoa/state-management'
import { useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'

import { useAuthState } from '../../../../../../../contexts'
import { useGetOffer } from '../../../../../../../hooks/flex2/risk'
import { useGetWallet } from '../../../../../../../hooks/flex2/wallet'
import { useSecureLineOfCredit } from '../../../../../../../hooks/secure-line-of-credit'
import { useAccount } from '../../../../../../../hooks/use-account'
import { useAddCardMutation, useSetupCardMutation } from '../../../../../../../modules/flexApi'
import { loggerV2, useAnalytics, useEnhancedTracking } from '../../../../../../../providers'
import { BillingAddress } from '../billing-address-types'
import { useCardResultMessage } from './use-card-result-message'
import { useConfirmSetupPayment } from './use-confirm-setup-payment-v2'
import { CardDetails, useCreatePaymentMethod } from './use-create-payment-method'

type RegisterCardStatus = 'none' | 'processing'

const INITIAL_CARD_DATA: CardDetails = {
  cardLast4Digits: '-',
  funding: '-',
  brand: '-',
}

type UseRegisterCard = {
  onSuccess: () => unknown
}

export const useRegisterCardV2 = (props: UseRegisterCard) => {
  const [registerCardStatus, setRegisterCardStatus] = useState<RegisterCardStatus>('none')
  const [cardElement, setCardElement] = useState<StripeCardElement | null>()
  const [billingAddress, setBillingAddress] = useState<BillingAddress>()
  const [cardData, setCardData] = useState<CardDetails>(INITIAL_CARD_DATA)
  const [clientSecret, setClientSecret] = useState<string>('')
  const [paymentMethodId, setPaymentMethodId] = useState('')
  const [createPaymentMethodResultId, setCreatePaymentMethodResultId] = useState<string>('')
  const [confirmedCard, setConfirmedCard] = useState(false)
  const [token, setToken] = useState<any>(undefined)
  const { getDefaultCard } = useGetWallet()
  const { getOffer } = useGetOffer()
  const { billerConnection } = useAccount()

  const { confirmSetupPayment } = useConfirmSetupPayment()
  const { createPaymentMethod } = useCreatePaymentMethod()
  const analytics = useAnalytics()
  const { trackPaymentConnected } = useEnhancedTracking()
  const stripe = useStripe()
  const { user } = useAuthState()

  const { isEnabledForSLC, error: responseErrorSLC, isFlagEnabledForSLC } = useSecureLineOfCredit()
  const [setupCard, { isLoading: setupCardLoading, error: setupCardError }] = useSetupCardMutation()
  const [addCard, { isLoading: addCardLoading, error: addCardError }] = useAddCardMutation()

  const resetRegisterCardData = useCallback(() => {
    setCardData(INITIAL_CARD_DATA)
    setRegisterCardStatus('none')
    setBillingAddress(undefined)
    setPaymentMethodId('')
    setClientSecret('')
    setToken(undefined)
  }, [])

  const handleCloseSuccessMessage = useCallback(() => {
    analytics.logEvent(Analytics.Events.CONNECT_CARD_SUCCESS)
    resetRegisterCardData()
    props.onSuccess()
  }, [props.onSuccess])

  const handleShowSuccessMessage = useCallback(() => {
    setRegisterCardStatus('none')
    analytics.logEvent(Analytics.Events.CARD_REGISTER_SUCCESSFUL, {
      funding: cardData?.funding,
      brand: cardData?.brand,
    })
    trackPaymentConnected(cardData?.brand)
  }, [cardData])

  const handleShowErrorMessage = useCallback(() => {
    setRegisterCardStatus('none')
    resetRegisterCardData()
  }, [])

  const { showErrorMessage, showSuccessMessage } = useCardResultMessage({
    onCloseSuccessMessage: handleCloseSuccessMessage,
    onShowSuccessMessage: handleShowSuccessMessage,
    onShowErrorMessage: handleShowErrorMessage,
  })

  const handleError = (
    reason: string,
    modalTitle?: string,
    modalDescription?: string,
    handleOnErrorClose?: () => void
  ) => {
    loggerV2.error(Analytics.Events.CARD_REGISTER_FAILURE, `reason: ${reason}`)
    analytics.logEvent(Analytics.Events.CARD_REGISTER_FAILURE)
    showErrorMessage(modalTitle, modalDescription, handleOnErrorClose)
  }

  const handleRateLimitError = (origin: string) => {
    handleError(
      `${origin} - Rate limit exceeded`,
      'Wait and try again',
      'We noticed you tried adding a card very quickly after your last attempt. Please wait a few moments before trying to add a card again.'
    )
  }

  const handleDataError = (error: { message: string; code: string }) => {
    const { code, message } = error
    handleError(code, 'Something went wrong', message || 'We cannot process your request at this time.')
  }

  const billingDetails = useMemo(
    () => ({
      address: {
        line1: billingAddress?.streetAddress,
        postal_code: billingAddress?.zip,
        state: billingAddress?.state,
        city: billingAddress?.city,
        country: 'US',
      },
      name: billingAddress?.name,
    }),
    [billingAddress]
  )

  const handleCreateSetupIntent = useCallback(
    async (customerId: string, paymentMethodId: string) => {
      const setupCardResult = await setupCard({
        customerId: customerId,
        stripe_payment_method_id: paymentMethodId,
        stripe_card_element_token: token.token.id,
        is_onboarding: true,
      }).unwrap()

      return setClientSecret(setupCardResult.data.stripe_client_secret ?? '')
    },
    [handleError, token]
  )

  useEffect(() => {
    if (createPaymentMethodResultId && user?.uid && token) {
      handleCreateSetupIntent(user?.uid, createPaymentMethodResultId)
    }
  }, [createPaymentMethodResultId, user?.uid, token])

  const addStripePayment = useCallback(
    async (uid: string) => {
      if (user?.uid && paymentMethodId && cardData.cardLast4Digits !== '-') {
        await addCard({
          customerId: uid,
          stripe_setup_intent_id: paymentMethodId,
          stripe_card_element_token: token.token.id,
        })

        await getDefaultCard(user?.uid)
        return showSuccessMessage(cardData.cardLast4Digits)
      }
    },
    [paymentMethodId, cardData, handleError, user?.uid]
  )

  useQueryError(setupCardError || addCardError, {
    onFlexApiError: ({ data: { error } }) => {
      analytics.logEvent(Analytics.Events.CARD_REGISTER_FAILURE)
      if (error.code === WalletErrorCode.W_CARD_LINKAGE_THRESHOLD) {
        return handleError(
          'handleStripeFailure - Card already exists',
          'Something went wrong',
          'The card you added has been marked as suspicious, so your account has been locked for security reasons.',
          () => {
            getOffer(billerConnection?.biller_account_public_id!)
            return true
          }
        )
      }

      if (error.code === WalletErrorCode.W_BLOCK_CAPITAL_ONE) {
        handleDataError(error)
        return true
      }

      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        handleRateLimitError('setupCard/addCard')
        return true
      } else {
        handleError(`unexpected status`)
        return true
      }
    },
  })

  useEffect(() => {
    if (user?.uid && paymentMethodId && confirmedCard) {
      setConfirmedCard(false)
      addStripePayment(user.uid)
    }
  }, [confirmedCard, user?.uid, paymentMethodId])

  const handleConfirmSetupPayment = useCallback(
    async (clientSecret: string, uid: string) => {
      try {
        if (!stripe || !cardElement) {
          return
        }
        const getSetupPaymentConfirmed = await confirmSetupPayment({
          handleError,
          clientSecret,
          stripe,
          customerId: uid,
        })

        if (getSetupPaymentConfirmed !== null) {
          setPaymentMethodId(getSetupPaymentConfirmed)
          setConfirmedCard(true)
        }
      } catch (err: any) {
        handleError(err?.message ?? 'unknown error confirming setup')
      }
    },
    [billingDetails, stripe, cardElement, cardData, addStripePayment, paymentMethodId]
  )

  useEffect(() => {
    if (clientSecret && user?.uid) {
      handleConfirmSetupPayment(clientSecret, user.uid)
    }
  }, [clientSecret, user?.uid])

  const handleCreatePaymentMethod = useCallback(async () => {
    try {
      if (!stripe || !cardElement) {
        return
      }

      const createPaymentMethodResult = await createPaymentMethod({
        initialCardData: INITIAL_CARD_DATA,
        billingDetails,
        handleError,
        cardElement,
        stripe,
      })

      if (createPaymentMethodResult && createPaymentMethodResult.card.funding === 'prepaid') {
        return handleError(
          'User is trying to use pre-paid card',
          undefined,
          'Prepaid cards are not accepted at this time. Please use a debit card.'
        )
      }

      if (isFlagEnabledForSLC && responseErrorSLC) {
        return handleError('Error when attempting to fetch SLC status')
      }

      if (isEnabledForSLC && createPaymentMethodResult?.card.funding === 'credit') {
        return handleError(
          'User is trying to use credit card, credit card not supported',
          undefined,
          'We’re currently unable to support credit cards. Please add a debit card instead.'
        )
      }

      if (createPaymentMethodResult && createPaymentMethodResult.country !== 'US') {
        return handleError(
          'User is trying to use a non US card',
          undefined,
          'We do not accept international-based cards at this time. Please use a US-based card.'
        )
      }

      if (createPaymentMethodResult) {
        setCreatePaymentMethodResultId(createPaymentMethodResult.id)
        setCardData(createPaymentMethodResult.card)
        handleCreateToken(cardElement)
      }
    } catch (err: any) {
      handleError(err?.message)
    }
  }, [billingDetails, cardElement, billingAddress, stripe, isEnabledForSLC, responseErrorSLC, isFlagEnabledForSLC])

  useEffect(() => {
    if (registerCardStatus === 'processing') {
      handleCreatePaymentMethod()
    }
  }, [registerCardStatus, cardElement])

  const handleCreateToken = useCallback(
    async (cardElement: any) => {
      if (!cardElement || !billingDetails) {
        return
      }

      if (cardElement && billingDetails?.address) {
        const generatedToken = await stripe?.createToken(cardElement, { currency: 'usd' })
        setToken(generatedToken)
      }
    },
    [billingDetails, cardElement]
  )

  const startRegisterCard = useCallback(
    (billingAddress: BillingAddress, cardElement: StripeCardElement) => {
      if (registerCardStatus === 'processing') {
        return
      }

      setRegisterCardStatus('processing')
      setBillingAddress(billingAddress)
      setCardElement(cardElement)
    },
    [registerCardStatus]
  )

  return {
    isLoading: registerCardStatus === 'processing',
    startRegisterCard,
  }
}
