import React, { useEffect } from 'react'
import { Agreement, Installment, PaymentMethodType } from '@genoa/domain'
import { EMBED_RELINK_ACCEPT_OFFER_DETAILS } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { useAutopilotEligibility, useFlexAnywhereUser, useIsEmbed } from '../../../../hooks'
import { useSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { useCustomerWalletState } from '../../../../modules/flex2/use-state'
import { useContent } from '../../../../providers/content'
import { AutopayToggle, Headline1, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { AcceptanceOfferAuthorize } from './AcceptanceOfferAuthorize'
import { PaymentSchedule } from './payment-schedule'

type AcceptanceOfferDetailsProps = {
  onLearnMore: () => unknown
  onMembershipLearnMore: () => unknown
  onChooseDate: () => unknown
  onAcceptTerms: () => unknown
  onNext: () => unknown
  loading: boolean
  termsAccepted: boolean
  rentAmount: number
  offerFee: number
  payments: Installment[]
  ctaTitle: string
  showExtraFees?: boolean
  processingFeePercentage?: number
  membershipFeeReason?: string
  agreements: Agreement[]
  isRelink?: boolean
}

export const AcceptanceOfferDetails = (props: AcceptanceOfferDetailsProps) => {
  const customerWalletState = useCustomerWalletState()
  const { isUserEligibleForAutopilot } = useAutopilotEligibility()
  const { isSimplifiedPaymentsEnabled, isEnabledForSLC, isPricingEnabled } = useSecureLineOfCredit()
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const theme = useTheme()
  const isEmbed = useIsEmbed()

  const {
    setUserContentRule,
    replaceContent,
    content: { ACCEPTANCE_OFFER_DETAILS },
  } = useContent()

  useEffect(() => {
    if (isOutOfNetworkUser) {
      setUserContentRule('OON_ACCEPTANCE_OFFER_CONTENT')
    } else if (isUserEligibleForAutopilot) {
      setUserContentRule('ACCEPTANCE_OFFER_DETAILS_NEW_CONTENT')
    }
    if (isEnabledForSLC) {
      if (isSimplifiedPaymentsEnabled) {
        setUserContentRule('ACCEPTANCE_OFFER_SLC_SIMPLIFIED_ENABLED_CONTENT')
      } else {
        setUserContentRule('ACCEPTANCE_OFFER_SLC_SIMPLIFIED_DISABLED_CONTENT')
      }
    }
  }, [isOutOfNetworkUser, isUserEligibleForAutopilot, isSimplifiedPaymentsEnabled])

  const feesDisclaimer = replaceContent(ACCEPTANCE_OFFER_DETAILS.FEE_DISCLAIMER, {
    processingFeePercentage: props.processingFeePercentage,
  })
  const creditFeesDisclaimer = replaceContent(ACCEPTANCE_OFFER_DETAILS.FEE_DISCLAIMER_CREDIT || '', {
    processingFeePercentage: props.processingFeePercentage,
  })

  const disclaimer =
    customerWalletState?.defaultCard?.card_type === PaymentMethodType.CREDIT ? creditFeesDisclaimer : feesDisclaimer

  return (
    <BasePageLayout>
      <Box height={theme.fixedSizes.spacing_200} />

      <Headline1>
        {props.isRelink ? EMBED_RELINK_ACCEPT_OFFER_DETAILS.HEADER : ACCEPTANCE_OFFER_DETAILS.HEADER}
      </Headline1>

      <Box minH={theme.fixedSizes.spacing_150} />

      <SmallText>{props.isRelink ? EMBED_RELINK_ACCEPT_OFFER_DETAILS.BODY : ACCEPTANCE_OFFER_DETAILS.BODY}</SmallText>

      <Box minH={theme.fixedSizes.spacing_250} />

      <PaymentSchedule
        payments={props.payments}
        fee={props.offerFee}
        onLearnMore={props.onLearnMore}
        onMembershipLearnMore={props.onMembershipLearnMore}
        onRepaymentDayChange={props.onChooseDate}
        showExtraFees={props.showExtraFees}
        showProcessingFeePercentage={!!props.processingFeePercentage}
        membershipFeeReason={props.membershipFeeReason}
        feeDisclaimerLabel={disclaimer}
        initialPaymentLabel={ACCEPTANCE_OFFER_DETAILS.INITIAL_PAYMENT_LABEL}
        hideChangeDate={isEnabledForSLC}
        isSimplifiedPaymentsEnabled={isSimplifiedPaymentsEnabled}
        isEnabledForSLC={isEnabledForSLC}
        showInterestFee={isPricingEnabled}
      />

      <Box height="32px" />

      {isEmbed && (
        <>
          <AutopayToggle />
          <Box height={theme.fixedSizes.spacing_150} />
        </>
      )}

      <AcceptanceOfferAuthorize
        agreeText={props.isRelink ? EMBED_RELINK_ACCEPT_OFFER_DETAILS.AUTHORIZATION : ACCEPTANCE_OFFER_DETAILS.AGREE}
        toggleAcceptTerms={props.onAcceptTerms}
        termsAccepted={props.termsAccepted}
        fetchedAgreements={props.agreements}
      />

      <Box height={theme.fixedSizes.spacing_150} />

      <PrimaryButton
        testID="AcceptanceOfferDetailsNext"
        onClick={props.onNext}
        disabled={!props.termsAccepted || props.loading}
        processing={props.loading}
      >
        {props.ctaTitle}
      </PrimaryButton>

      <Box minH={theme.fixedSizes.spacing_100} />
    </BasePageLayout>
  )
}
