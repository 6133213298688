import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'
import { IntegrationType, SLCType } from '@genoa/domain'
import sha256 from 'crypto-js/sha256'

import { useEmbedFlow } from '../../hooks'
import { useAmplitude } from '../amplitude'
import { useAnalytics } from '../analytics'
import { getAllMarketingCampaignData } from './use-marketing-campaign-data'

const { Events: E, Screens: S } = Analytics

const productTypeToTrackingValue = (productType?: SLCType) => {
  if (productType === undefined || productType === null) {
    return 'RENT_SPLIT_LEGACY'
  } else if (productType === SLCType.CLASSIC) {
    return 'SLC'
  }
  return SLCType[productType]
}

const integrationTypeToTrackingValue = (integrationType: IntegrationType, billerIntegrationType?: string | null) => {
  switch (integrationType) {
    case IntegrationType.YARDI:
    case IntegrationType.REALPAGE:
      return `${IntegrationType[IntegrationType.DIRECT_INTEGRATION]}_${IntegrationType[integrationType]}`
    case IntegrationType.DIRECT_INTEGRATION:
      if (billerIntegrationType !== undefined && billerIntegrationType !== null) {
        return `${IntegrationType[IntegrationType.DIRECT_INTEGRATION]}_${billerIntegrationType}`
      }
      return IntegrationType[IntegrationType.DIRECT_INTEGRATION]
    case IntegrationType.PORTAL:
    case IntegrationType.FLEX_ANYWHERE:
      return IntegrationType[integrationType]
    default:
      return integrationType
  }
}

/**
 * hook providing an easy getter/setter interface to writing and accessing JSON objects to localStorage
 */
const useLocalStorageEventProperties = () => {
  const KEY = 'enhanced_shared_evt_props'
  const get = () => {
    const readValue = localStorage.getItem(KEY)
    if (readValue === undefined || readValue === null) {
      return {}
    }
    // safe parse JSON string read from Local Storage
    try {
      return JSON.parse(readValue)
    } catch (error) {
      localStorage.removeItem(KEY)
      return {}
    }
  }

  const set = (properties?: Record<string, string>) => {
    if (properties !== undefined) {
      localStorage.setItem(KEY, JSON.stringify(properties))
    } else {
      localStorage.removeItem(KEY)
    }
    return properties
  }

  return { get, set }
}

const useWrappedTrackingMethods = () => {
  const {
    enhancedTracking: {
      isEnabled: isEnhancedTrackingEnabled,
      addAndGetAllSharedEventProperties: _addAndGetAllSharedEventProperties,
      getAllSharedEventProperties,
    },
  } = useAmplitude()
  const embedFlow = useEmbedFlow()

  // wrap addAndGetAllSharedEventProperties function from useAmplitude() hook to persist in localStorage
  const { get: lsGet, set: lsSet } = useLocalStorageEventProperties()
  const addAndGetAllSharedEventProperties = (properties: Record<string, string>) => {
    return lsSet(
      _addAndGetAllSharedEventProperties({
        ...lsGet(),
        ...properties,
      })
    )
  }

  // wrap logEvent function from useAnalytics() hook to only pass events thru if Enhanced Tracking is enabled
  const { logEvent: _logEvent, logScreenView } = useAnalytics()

  const logEvent = (name: Analytics.Events, data?: any) => {
    if (isEnhancedTrackingEnabled()) {
      // only pass the event through only if Enhanced Tracking is enabled
      _logEvent(name, { ...data, platform: 'webapp' })
    }
  }

  // persist marketing data as shared event properties, once for this hook use
  useEffect(() => {
    addAndGetAllSharedEventProperties({ ...getAllMarketingCampaignData(), embedFlow })
  }, [])

  return {
    logEvent,
    logScreenView,
    getAllSharedEventProperties,
    addAndGetAllSharedEventProperties,
  }
}

export const useEnhancedTracking = () => {
  const { logEvent, logScreenView, getAllSharedEventProperties, addAndGetAllSharedEventProperties } =
    useWrappedTrackingMethods()

  const trackPhoneConsolidationScreenView = () => {
    logScreenView(S.SIGNIN_PHONE_NUMBER, {
      ...getAllSharedEventProperties(),
      consolidated: true,
    })
  }

  const trackPhoneVerified = (customerId: string | undefined) => {
    logEvent(
      E.PHONE_VERIFIED,
      addAndGetAllSharedEventProperties({
        ...(customerId !== undefined && { customerId }),
      })
    )
  }

  const trackAccountCreated = (email: string) => {
    logEvent(
      E.ACCOUNT_CREATED,
      addAndGetAllSharedEventProperties({
        email: sha256(email).toString(),
      })
    )
  }

  const trackBillConnected = (
    propertyName: string,
    pmcName: string,
    integrationType: IntegrationType,
    billerIntegrationType: string | null
  ) => {
    logEvent(
      E.BILL_CONNECTED,
      addAndGetAllSharedEventProperties({
        propName: propertyName,
        pmcName: pmcName,
        integrationType: integrationTypeToTrackingValue(integrationType, billerIntegrationType),
      })
    )
  }

  const trackUnderwritingRequested = (productType?: SLCType, rentAmount?: string) => {
    logEvent(
      E.UNDERWRITING_REQUESTED,
      addAndGetAllSharedEventProperties({
        productType: productTypeToTrackingValue(productType),
        ...(rentAmount !== undefined && { rentAmount }),
      })
    )
  }

  const trackClickedContinueOnLoadApprovalModal = (rentAmount?: string) => {
    logEvent(
      E.CUSTOMER_ACCEPTED_LOAN,
      addAndGetAllSharedEventProperties({
        ...(rentAmount !== undefined && { rentAmount }),
      })
    )
  }

  const trackPaymentConnected = (brand?: string) => {
    logEvent(
      E.PAYMENT_CONNECTED,
      addAndGetAllSharedEventProperties({
        ...(brand !== undefined && { cardNetwork: brand }),
      })
    )
  }

  const trackUserSignUpCompleted = () => {
    logEvent(E.SIGNUP_COMPLETED, getAllSharedEventProperties())
  }

  return {
    trackPhoneConsolidationScreenView,
    trackPhoneVerified,
    trackAccountCreated,
    trackBillConnected,
    trackUnderwritingRequested,
    trackClickedContinueOnLoadApprovalModal,
    trackPaymentConnected,
    trackUserSignUpCompleted,
  }
}
