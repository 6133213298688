import { useEffect } from 'react'
import * as cookie from 'cookie'

const MARKETING_DATA_KEYS_OF_INTEREST = new Set([
  // Click Identifiers
  'gclid', // Google
  'fbclid', // Facebook
  'ttclid', // TikTok
  'li_fat_id', // LinkedIn
  // UTM Campaign Data follows...
  'utm_campaign',
  'utm_content',
  'utm_id',
  'utm_medium',
  'utm_source',
  'utm_term',
  // Geolocation Information
  'city',
  'region',
])

const LOCALSTORAGE_MARKETING_DATA_KEY = 'marketing_campaign_data'

const retrieveStorageMarketingData = (storage: Storage) => {
  const storedValue = storage.getItem(LOCALSTORAGE_MARKETING_DATA_KEY)
  if (storedValue === null) {
    return {}
  }
  // attempt a JSON.parse on string value read; if parse fails, delete the corrupted key from storage
  try {
    return JSON.parse(storedValue)
  } catch (error) {
    storage.removeItem(LOCALSTORAGE_MARKETING_DATA_KEY)
    return {}
  }
}

const parseCookieData = (cookieValue: string) => cookie.parse(cookieValue)
const parseQueryStringData = (qs: string) => Object.fromEntries(new URLSearchParams(qs))

const collectedCampaignData: Record<string, string> = {}

export const useMarketingCampaignData = () => {
  useEffect(() => {
    const qsData = parseQueryStringData(window.location.search)
    const localStoreData = retrieveStorageMarketingData(localStorage)
    const cookieData = parseCookieData(document.cookie)

    for (const dataKey of MARKETING_DATA_KEYS_OF_INTEREST) {
      const resolvedValue =
        // first see if information is available as a query string parameter
        qsData[dataKey] ||
        // if not, then see if we have any information shared as cookies from the marketing website
        cookieData[dataKey] ||
        // finally, fall back to values from localStorage that would've been persisted from a previous visit
        localStoreData[dataKey]

      if (resolvedValue !== undefined) {
        collectedCampaignData[dataKey] = resolvedValue.toString()
      }
    }

    // once we've collected all known Marketing Campaign Data, time to persist it for future visits in Local Storage
    if (Object.keys(collectedCampaignData).length > 0) {
      localStorage.setItem(LOCALSTORAGE_MARKETING_DATA_KEY, JSON.stringify(collectedCampaignData))
    }
  }, [])
}

export const getAllMarketingCampaignData = () =>
  Object.entries(collectedCampaignData).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: value,
    }),
    {}
  )
