import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { ActionLogType, Installment } from '@genoa/domain'
import { OnboardingStep, useSetOfferSchedule } from '@genoa/middle-end'
import { ordinalSuffixFor, toDollars, toFormattedDollars } from '@genoa/utils'

import { useAuthState } from '../../../../contexts'
import { useReduxAction, useReduxSelector } from '../../../../hooks'
import { useHandleRegisterActionLog, useOnboardingStatus } from '../../../../hooks/flex2/onboarding-status'
import { useSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { RootState } from '../../../../modules'
import { OfferState, setRepaymentDayAction } from '../../../../modules/flex2/offer'
import { FeesService, useAnalytics, useFees } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { FullScreenSpinnerLoading } from '../../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { useSetScheduleRange } from '../use-set-schedule-range'
import { CustomizeYourSchedule } from './CustomizeYourSchedule'
import { CustomizeYourScheduleSLC } from './CustomizeYourScheduleSLC'
import { ReinforcementMessage } from './ReinforcementMessageWidget'
import { SecondPaymentDay } from './second-payment-days-list/SecondPaymentList'
import { SimplifiedPaymentChoice } from './SimplifiedPayments'

export type PaymentInformation = {
  subscriptionFeeFormatted: string
  firstPaymentAmountFormatted: string
  firstPaymentBaseAmount: number
  firstPaymentDisplayProportion: number
  secondPaymentAmountFormatted: string
  secondPaymentBaseAmount: number
  secondPaymentDisplayProportion: number
  rentAmountFormatted: string
  processingFeePercentage: number
  isCreditBelowThreshold: boolean
}

const getFirstAndSecondPayment = (installments: Installment[], isEnabledForSLC: boolean) => {
  if (isEnabledForSLC) {
    return installments
  }

  const firstPayment = installments.find((installment) => {
    return installment.day === 0
  })
  const secondPayment = installments.find((installment) => {
    return installment.day !== 0
  })

  return [firstPayment, secondPayment]
}

const threshold = 50
const minProportion = 40

const normalizeProportions = (firstProportion: number, secondProportion: number) => {
  if (firstProportion < minProportion) {
    return [minProportion, 60]
  }
  if (secondProportion < minProportion) {
    return [60, minProportion]
  }
  return [firstProportion, secondProportion]
}

export const getApprovedPaymentInformation = (fees: FeesService, isEnabledForSLC: boolean): PaymentInformation => {
  const { installments, subscriptionFee, processingFeePercentage } = fees
  const [firstPayment, secondPayment] = getFirstAndSecondPayment(installments, isEnabledForSLC)

  const firstPaymentProportion = Math.round(firstPayment?.proportion || 0)
  const secondPaymentProportion = 100 - firstPaymentProportion

  const firstPaymentBaseAmount = firstPayment?.baseAmount || 0
  const secondPaymentBaseAmount = secondPayment?.baseAmount || 0

  const firstPaymentAmount = isEnabledForSLC ? firstPayment?.totalAmount : firstPaymentBaseAmount
  const secondPaymentAmount = isEnabledForSLC ? secondPayment?.totalAmount : secondPaymentBaseAmount

  const rentAmount = firstPaymentBaseAmount + secondPaymentBaseAmount

  const firstPaymentAmountFormatted = toFormattedDollars(firstPaymentAmount)
  const subscriptionFeeFormatted = toFormattedDollars(subscriptionFee)
  const secondPaymentAmountFormatted = toFormattedDollars(secondPaymentAmount)
  const rentAmountFormatted = toFormattedDollars(rentAmount)

  const isCreditBelowThreshold = secondPaymentProportion < threshold

  const [firstPaymentDisplayProportion, secondPaymentDisplayProportion] = normalizeProportions(
    firstPaymentProportion,
    secondPaymentProportion
  )

  return {
    subscriptionFeeFormatted,
    firstPaymentAmountFormatted,
    firstPaymentBaseAmount,
    secondPaymentBaseAmount,
    firstPaymentDisplayProportion,
    secondPaymentAmountFormatted,
    secondPaymentDisplayProportion,
    rentAmountFormatted,
    isCreditBelowThreshold,
    processingFeePercentage,
  }
}

export const CustomizeYourScheduleContainer = () => {
  const { user } = useAuthState()
  const [daySelected, setDaySelected] = useState<SecondPaymentDay | undefined>()
  const [schedule, setSchedule] = useState<number[]>([])
  const offerState: OfferState = useReduxSelector((state: RootState) => state.offerState)
  const setRepaymentDay = useReduxAction(setRepaymentDayAction)
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const [showReinforcementMessage, setShowReinforcementMessage] = useState<ReinforcementMessage | undefined>(undefined)
  const setCurrentSchedule = useSetScheduleRange({ componentName: 'CustomizeYourScheduleContainer' })
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()

  const {
    isEnabledForSLC,
    isLoadingSLC,
    refetchSLC,
    isRefetchingSLC,
    isPricingEnabled,
    error: errorFetchingSLC,
  } = useSecureLineOfCredit()
  const [simplifiedPaymentChoice, setSimplifiedPaymentChoice] = useState<SimplifiedPaymentChoice | undefined>(undefined)
  const [simplifiedPaymentsInfoVisible, setSimplifiedPaymentsInfoVisible] = useState(false)

  const [{ loading }, setOfferSchedule] = useSetOfferSchedule()

  const { resolveNextStep } = useOnboardingStatus()

  const fees = useFees()

  const paymentInformation = useMemo(
    () => getApprovedPaymentInformation(fees, isEnabledForSLC),
    [fees, isEnabledForSLC]
  )

  useEffect(() => {
    if (!offerState?.offer?.offer_id) {
      return
    }
    setCurrentSchedule.setScheduleRange(setSchedule)
  }, [offerState?.offer?.offer_id])

  useEffect(() => {
    analytics.logScreenView(Analytics.Screens.CUSTOMIZE_YOUR_SCHEDULE)
  }, [])

  useEffect(() => {
    if (!daySelected) {
      return
    }
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_SELECT_DAY, {
      day: daySelected.day,
    })
  }, [daySelected])

  const secondPaymentDays = useMemo(() => {
    if (schedule.length === 0) {
      return []
    }
    const [startDay, endDay] = schedule
    return Array(endDay - startDay + 1)
      .fill({})
      .map((_, index) => {
        const ordinalSuffix = ordinalSuffixFor(index + startDay)
        const ordinal = ordinalSuffix.slice(ordinalSuffix.length - 2)
        return {
          day: index + startDay,
          ordinal,
        }
      })
  }, [schedule])

  const handleSetReinforcementMessage = (date: number) => {
    if (date) {
      if (date <= schedule[1] - 8) {
        setShowReinforcementMessage(ReinforcementMessage.POSITIVE)
      } else if (date >= schedule[1] - 7) {
        setShowReinforcementMessage(ReinforcementMessage.NEGATIVE)
      } else {
        setShowReinforcementMessage(undefined)
      }
    }
  }

  const handleClickSecondPaymentDay = (daySelected: SecondPaymentDay) => {
    handleSetReinforcementMessage(daySelected.day)
    setDaySelected(daySelected)
  }

  const tryRegisterSimplifiedPaymentsAction = useCallback(async () => {
    if (isEnabledForSLC) {
      const action =
        simplifiedPaymentChoice === 'on'
          ? ActionLogType.ENABLE_SIMPLIFIED_PAYMENTS
          : ActionLogType.DISABLE_SIMPLIFIED_PAYMENTS

      await handleRegisterActionLog(action)
      analytics.logEvent(Analytics.Events.SIMPLIFIED_PAYMENTS_SETTING_CTA, {
        enabled: simplifiedPaymentChoice === 'on',
      })
    }
  }, [isEnabledForSLC, simplifiedPaymentChoice])

  const handleSetSimplifiedPaymentsVisible = (value: boolean) => {
    if (value) {
      analytics.logEvent(Analytics.Events.SIMPLIFIED_PAYMENTS_INFO_CLICKED)
    }

    setSimplifiedPaymentsInfoVisible(value)
  }

  const handleConfirmButton = async () => {
    if (!daySelected || !user?.uid) {
      return
    }

    await setOfferSchedule({
      simple_one_repayment_schedule: { repayment_day: daySelected.day },
      offerId: offerState.offer.offer_id,
      customerPublicId: user.uid,
    })

    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_CONFIRM_CLICKED)
    setRepaymentDay(daySelected.day)

    await tryRegisterSimplifiedPaymentsAction()
    await refetchSLC()

    resolveNextStep(OnboardingStep.REPAYMENT_SCHEDULING, () => navigate(Routes.Onboarding.OFFER_DETAILS))
  }

  const handleConfirmButtonSLC = async () => {
    if (!user?.uid) {
      return
    }
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_CONFIRM_CLICKED)
    await tryRegisterSimplifiedPaymentsAction()
    navigate(Routes.Onboarding.CHOOSE_DATE)
    refetchSLC()
  }

  const handleClickConfirmButton = () => {
    if (isEnabledForSLC) {
      handleConfirmButtonSLC()
    } else {
      handleConfirmButton()
    }
  }

  if (errorFetchingSLC) {
    navigate(Routes.App.TRY_AGAIN)
    return <></>
  }

  if (isLoadingSLC || isRefetchingSLC) {
    return <FullScreenSpinnerLoading />
  }

  if (isEnabledForSLC) {
    return (
      <CustomizeYourScheduleSLC
        onClickConfirmButton={handleClickConfirmButton}
        isConfirmButtonDisabled={!simplifiedPaymentChoice}
        showReinforcementMessage={showReinforcementMessage}
        loading={loading || loadingRegisterActionLog}
        simplifiedPaymentChoice={simplifiedPaymentChoice}
        setSimplifiedPaymentChoice={setSimplifiedPaymentChoice}
        simplifiedPaymentsInfoVisible={simplifiedPaymentsInfoVisible}
        setSimplifiedPaymentsInfoVisible={handleSetSimplifiedPaymentsVisible}
        paymentInformation={paymentInformation}
        hideMembershipFee={!fees.subscriptionFee}
        showInterestFee={isPricingEnabled}
      />
    )
  }

  return (
    <CustomizeYourSchedule
      onSelectSecondPaymentDay={handleClickSecondPaymentDay}
      onClickConfirmButton={handleClickConfirmButton}
      isConfirmButtonDisabled={!daySelected}
      secondPaymentDays={secondPaymentDays}
      daySelected={daySelected}
      showReinforcementMessage={showReinforcementMessage}
      loading={loading}
      paymentInformation={paymentInformation}
    />
  )
}
