import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { FlexLinks, SLCType } from '@genoa/domain'
import { useRestartOnboarding } from '@genoa/middle-end'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useAuthState, useHelpMenuActions } from '../../../contexts'
import { useIsEmbed } from '../../../hooks'
import { isBlockedOffer } from '../../../hooks/flex2/risk'
import { useAccount } from '../../../hooks/use-account'
import { useOfferState } from '../../../modules/flex2/use-state'
import { useAnalytics, useAuth, useFlexLinks } from '../../../providers'
import * as Routes from '../../../routing/constants'
import { ChevronRight } from '../../assets'
import { Modal } from '../Modal'
import { LargeText } from '../Typography'

export type Action = {
  action: () => unknown
  label: string
}

export type DefaultActions = 'contact-flex' | 'restart-account-setup'

type HelpMenuSheetProps = {
  analyticsScreenName?: Analytics.Screens
  onClose?: () => unknown
  actions?: Action[]
  visible: boolean
}

export const HelpMenu = (props: HelpMenuSheetProps) => {
  const actions = useHelpMenuActions()
  const analytics = useAnalytics()
  const theme = useTheme()
  const auth = useAuth()
  const flexLinks = useFlexLinks()
  const [_, restartOnboarding] = useRestartOnboarding()
  const offerState = useOfferState()
  const navigate = useNavigate()
  const { user } = useAuthState()
  const { isActive } = useAccount()
  const isEmbed = useIsEmbed()

  useEffect(() => {
    if (props.visible) {
      analytics.logEvent(Analytics.Events.HELP_MENU_OPENED, {
        screen: props.analyticsScreenName,
      })
    }
  }, [props.visible])

  const onClickHelpCenter = useCallback(() => {
    analytics.logEvent(Analytics.Events.HELP_MENU_HELP_CENTER_CLICKED, {
      screen: props.analyticsScreenName,
    })

    flexLinks.open(FlexLinks.helpHome)
  }, [props.analyticsScreenName, flexLinks.open])

  const onClickRestartAccountSetup = async () => {
    analytics.logEvent(Analytics.Events.HELP_MENU_RESTART_ACCOUNT_SETUP_CLICKED, {
      screen: props.analyticsScreenName,
    })
    if (user?.uid && !isBlockedOffer(offerState)) {
      await restartOnboarding({ customerPublicId: user.uid })
    }
    props.onClose?.()
    auth.signOut()
  }

  const onClickProductSelection = async () => {
    analytics.logEvent(Analytics.Events.HELP_MENU_PRODUCT_SELECTION_CLICKED, {
      screen: props.analyticsScreenName,
    })

    if (user?.uid && !isBlockedOffer(offerState)) {
      await restartOnboarding({ customerPublicId: user.uid })
    }

    navigate(Routes.Onboarding.SLC_PRODUCT_SELECTION)
  }

  const onClose = useCallback(() => {
    analytics.logEvent(Analytics.Events.HELP_MENU_CLOSED, {
      screen: props.analyticsScreenName,
    })

    props.onClose?.()
  }, [props.analyticsScreenName, props.onClose])

  const onIHaveNewPhoneNumber = () => {
    analytics.logEvent(Analytics.Events.HELP_MENU_NEW_PHONE_NUMBER_CLICKED)
    flexLinks.open(FlexLinks.phoneNumberFAQ)
  }

  return (
    <Modal visible={props.visible} onClose={onClose} canClose title="How can we help?">
      <>
        <OptionWrapper>
          <ListItem onClick={onIHaveNewPhoneNumber} as="button">
            <LargeText>I have a new phone number</LargeText>
            <ChevronRight />
          </ListItem>
        </OptionWrapper>
        <Separator />
        <Box minH={theme.fixedSizes.md} />
      </>

      <OptionWrapper>
        <ListItem onClick={onClickHelpCenter} as="button">
          <LargeText>Help center</LargeText>
          <ChevronRight />
        </ListItem>
      </OptionWrapper>

      <Separator />

      <OptionWrapper marginTop={theme.fixedSizes.md}>
        <ListItem onClick={onClickRestartAccountSetup} as="button">
          <LargeText>{'Restart signup'}</LargeText>
          <ChevronRight />
        </ListItem>
      </OptionWrapper>

      <Separator />

      {!user?.isAnonymous && !isActive && !isEmbed && (
        <>
          <OptionWrapper marginTop={theme.fixedSizes.md}>
            <ListItem onClick={onClickProductSelection} as="button">
              <LargeText>{'Explore all Flex services'}</LargeText>
              <ChevronRight />
            </ListItem>
          </OptionWrapper>

          <Separator />
        </>
      )}

      {actions.map((action) => (
        <OptionWrapper key={action.label} marginTop={theme.fixedSizes.md}>
          <ListItem onClick={action.action} as="button">
            <LargeText>{action.label}</LargeText>
            <ChevronRight />
          </ListItem>

          <Separator />
        </OptionWrapper>
      ))}
    </Modal>
  )
}

const OptionWrapper = styled(Box)`
  width: 100%;
`

const ListItem = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
`

const Separator = styled(Box)`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  margin-top: ${(props) => props.theme.fixedSizes.md};
`
