import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { EMBED_CONGRATS_CONTENT as content } from '@genoa/screen-content'
import { GeneralApiErrorCodes } from '@genoa/state-management'
import { getDateTime, getResumeDate } from '@genoa/utils'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import {
  useAutopilotEligibility,
  useDefaultErrorModals,
  useEmbedAnalytics,
  useFlexAnywhereUser,
} from '../../../../../../hooks'
import { useAnalytics } from '../../../../../../providers'
import { useContent } from '../../../../../../providers/content'
import * as Routes from '../../../../../../routing/constants'
import { CongratsFlag as CongratsImageFlag, HeroBouquet as CongratsImage } from '../../../../../assets'
import { AppStore, Gap, Headline1, PrimaryButton, Text } from '../../../../../components'
import { FullScreenSpinnerLoading } from '../../../../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedCongrats = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { errorCode } = state || {}
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const { isUserAutopayEnabled, isLoadingEligibility } = useAutopilotEligibility()
  const { displayTooManyTriesModal, displayDefaultErrorModal } = useDefaultErrorModals()
  const { embedFlow, embedProperties } = useEmbedAnalytics()
  const analytics = useAnalytics()

  const heroImage = isUserAutopayEnabled ? <CongratsImageFlag /> : <CongratsImage />
  const { setUserContentRule } = useContent()
  const { monthLong } = getResumeDate(getDateTime())
  const bodyText = useMemo(
    () => (
      <Text textAlign="center">
        {content.BODY_1} <BoldText>{monthLong}</BoldText>. {content.BODY_2}
      </Text>
    ),
    [content, monthLong]
  )

  const onConfirmSplitRent = () => {
    analytics.logEvent(Analytics.Events.EMBED_CONGRATS_AUTOPAY_OFF_BUTTON_CLICK, { embedFlow })
    navigate(Routes.Embed.SIMPLIFIED_EPISODIC)
  }

  useEffect(() => {
    if (!isOutOfNetworkUser) {
      const contentRule = isUserAutopayEnabled ? 'CONGRATS_NEW_CONTENT' : 'CONGRATS_AUTOPILOT_OFF_CONTENT'
      setUserContentRule(contentRule)
    }
  }, [isOutOfNetworkUser, isUserAutopayEnabled])

  useEffect(() => {
    if (errorCode) {
      switch (errorCode) {
        case GeneralApiErrorCodes.RATE_LIMIT:
          displayTooManyTriesModal()
          break
        case 'default':
          displayDefaultErrorModal('PayNow')
          break
        default:
          displayDefaultErrorModal('PayNow')
      }
    }
  }, [errorCode])

  if (isLoadingEligibility) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.EMBED_CONGRATS_AUTOPAY_OFF}
      analyticsScreenParams={embedProperties}
    >
      <Gap size="spacing_300" />

      <Flex flexGrow={1} flexShrink={0} flexDirection="column" data-testid="EmbedCongratsPage">
        <Flex align="center" justify="center">
          {heroImage}
        </Flex>

        <Gap size="spacing_225" />
        <Headline1 textAlign="center">{content.HEADER}</Headline1>
        <Gap size="spacing_100" />
        {bodyText}

        <Gap size="spacing_200" />
        <AppStore />

        <Gap size="spacing_200" />
        <PrimaryButton onClick={onConfirmSplitRent}>{content?.CTA}</PrimaryButton>
      </Flex>

      <Gap size="spacing_200" />
    </BasePageLayout>
  )
}

const BoldText = styled.span`
  font-weight: bold;
`
