import React, { useCallback, useMemo } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'

import { HelpMenuActionsProvider } from '../../../contexts'
import { useAnalytics, useFlexLinks } from '../../../providers'
import { NotApproved } from './NotApproved'

export const NotApprovedContainer = () => {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  const handleNext = useCallback(() => {
    analytics.logEvent(Analytics.Events.NOT_APPROVED_CTA_CLICKED)
    flexLinks.open(FlexLinks.notApprovedInfo)
  }, [])

  const handleFAQ = useCallback(() => {
    analytics.logEvent(Analytics.Events.NOT_APPROVED_HELP_FAQ_CLICKED)
    flexLinks.open(FlexLinks.helpHome)
  }, [flexLinks.open])

  const actions = useMemo(
    () => [
      {
        label: 'Frequently Asked Questions',
        action: () => handleFAQ(),
      },
    ],
    [flexLinks.open]
  )

  return (
    <HelpMenuActionsProvider actions={actions}>
      <NotApproved onMessageSupport={handleNext} />
    </HelpMenuActionsProvider>
  )
}
