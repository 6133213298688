import { useEffect } from 'react'

import { useEmbedBlockScreens } from './use-embed-blocking-screens'

export const useCheckPayNowEligibility = () => {
  const { checkBlockingStates, embedPayNowEligibility, embedPayNowEligibilityQuery } = useEmbedBlockScreens()

  useEffect(() => {
    if (embedPayNowEligibility) {
      checkBlockingStates()
    }
  }, [embedPayNowEligibility])

  return {
    embedPayNowEligibility,
    isPaynowEligibilityLoading: !embedPayNowEligibility || !embedPayNowEligibility.data.eligible,
    embedPayNowEligibilityQuery,
  }
}
