import { useEffect } from 'react'
import { OnboardingStep } from '@genoa/middle-end'
import { resetOnboardingStatusAction, resetUserAccountAction, setResumeStepAction } from '@genoa/state-management'

import { useAuthState } from '../../contexts'
import { useReduxAction } from '../../hooks'
import { useHandleGetOnboardingStatus } from '../../hooks/flex2/onboarding-status'
import { useGetPricing } from '../../hooks/flex2/pricing'
import { useGetOffer } from '../../hooks/flex2/risk'
import { useHandleGetUserAccount } from '../../hooks/flex2/user-account/use-get-user-account'
import { useGetWallet } from '../../hooks/flex2/wallet'
import { useAccount } from '../../hooks/use-account'
import { useSetFeatureStatusState } from '../../hooks/use-set-feature-status-state'
import { resetCustomerCardAction } from '../../modules/flex2/customer-wallet'
import { resetOfferAction } from '../../modules/flex2/offer'
import { resetPricingOfferAction } from '../../modules/flex2/pricing-offer'
import { ResetLoadedPayload } from '../../modules/flex2/types'
import { useOfferState } from '../../modules/flex2/use-state'
import { useAnalytics } from '../analytics'

export const IdentityServiceProvider = ({ children }: { children: JSX.Element }) => {
  const { user, isAnonymous, tokenIsReady, setTokenIsReady, uid } = useAuthState()
  const { billerConnection } = useAccount()
  const { getOffer } = useGetOffer()
  const { getPricingOffer } = useGetPricing()
  const { getDefaultCard } = useGetWallet()
  const { handleGetUserAccount } = useHandleGetUserAccount()
  const offerState = useOfferState()
  const { fetchFeatureStatuses } = useSetFeatureStatusState()
  const resetOnboardingStatus = useReduxAction<void>(resetOnboardingStatusAction)
  const { handleGetOnboardingStatus } = useHandleGetOnboardingStatus()

  const resetOffer = useReduxAction<void>(resetOfferAction)
  const resetPricingOffer = useReduxAction<ResetLoadedPayload | void>(resetPricingOfferAction as any)
  const resetCustomerCard = useReduxAction<void>(resetCustomerCardAction)
  const resetUserAccount = useReduxAction<void>(resetUserAccountAction)
  const setResumeStep = useReduxAction<OnboardingStep | undefined>(setResumeStepAction)

  useEffect(() => {
    setResumeStep(undefined)
  }, [])

  const analytics = useAnalytics()

  // user changed
  useEffect(() => {
    if (!isAnonymous && user?.uid && tokenIsReady) {
      // Set timeout to make sure the token has been switched on the middleend call
      setTimeout(async () => {
        handleGetUserAccount(user.uid)
      }, 0)
    } else if (isAnonymous) {
      // Handles logout/disconnects
      resetIdentityContext()
    }
  }, [user?.uid, isAnonymous, tokenIsReady])

  useEffect(() => {
    if (!tokenIsReady || !uid || isAnonymous) {
      return
    }
    getDefaultCard(uid)
  }, [uid, tokenIsReady, isAnonymous])

  useEffect(() => {
    if (!tokenIsReady || !billerConnection?.biller_account_public_id) {
      return
    }
    // Only call get offers when customer changes
    getOffer(billerConnection.biller_account_public_id)
    getPricingOffer(billerConnection.biller_account_public_id)
  }, [billerConnection?.biller_account_public_id, tokenIsReady])

  useEffect(() => {
    if (offerState.initialized && !isAnonymous) {
      fetchFeatureStatuses()
    }
  }, [isAnonymous, offerState])

  useEffect(() => {
    if (!isAnonymous && user && tokenIsReady) {
      handleGetOnboardingStatus(user.uid, 'treatment')
    }
  }, [user?.uid, isAnonymous, tokenIsReady])

  const resetIdentityContext = () => {
    resetOffer()
    resetPricingOffer()
    resetCustomerCard()
    resetUserAccount()
    resetOnboardingStatus()
    setTokenIsReady(false)
  }

  return children
}
