import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerPropertyUnit, FlexLinks } from '@genoa/domain'
import { useGetBillerPropertyUnits } from '@genoa/middle-end'
import { setBillerUnitAction } from '@genoa/state-management'
import { checkIsUnitMatched } from '@genoa/utils'

import { useConnectBiller, useReduxAction, useReduxSelector } from '../../../../../hooks'
import { ConnectBillerType } from '../../../../../hooks/biller/types'
import { useShowNoMatchError } from '../../../../../hooks/biller/use-show-no-match-error'
import { usePrefills } from '../../../../../hooks/prefills'
import { RootState } from '../../../../../modules'
import { useAnalytics, useFlexLinks } from '../../../../../providers'
import { Keyboard } from '../../../../../tools/keyboard'
import { DirectIntegrationUnitNotListed } from './DirectIntegrationUnitNotListed'
import { DirectIntegrationUnitSelection } from './DirectIntegrationUnitSelection'

type DirectIntegrationUnitSelectionContainerProps = {
  analyticsScreenName: Analytics.Screens
  analyticsEventNoneOfTheAboveClicked: Analytics.Events
  analyticsEventStartOverClicked: Analytics.Events
  analyticsEventUnitSelected: Analytics.Events
  analyticsEventUnitConfirmClicked: Analytics.Events
  analyticsEventContactSupportClicked: Analytics.Events
  isRelink?: boolean
  onStartOver: () => unknown
  onNext: () => unknown
}

export const DirectIntegrationUnitSelectionContainer = (props: DirectIntegrationUnitSelectionContainerProps) => {
  const [notListedModalOpen, setNotListedModalOpen] = useState(false)
  const [unitsInitialized, setUnitsInitialized] = useState(false)
  const [units, setUnits] = useState<readonly BillerPropertyUnit[] | undefined>()
  const [query, setQuery] = useState('')
  const [selectedUnit, setSelectedUnit] = useState<BillerPropertyUnit | undefined>(undefined)

  const { resetPrefills, prefillIsActive } = usePrefills()
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  const [, getBillerPropertyUnits] = useGetBillerPropertyUnits()

  const setBillerUnit = useReduxAction(setBillerUnitAction)
  const property = useReduxSelector(({ propertyLinking }: RootState) => propertyLinking.property)!

  const { renderNoMatchError } = useShowNoMatchError()
  const { connecting, connectBiller } = useConnectBiller({
    onConnected: props.onNext,
    onError: renderNoMatchError,
    onSamePropertyRelink: props.onNext,
    componentName: 'DirectIntegrationUnitSelectionContainer',
    connectBillerType: ConnectBillerType.DIRECT_INTEGRATION,
  })

  useEffect(() => {
    if (!unitsInitialized && property.property_id) {
      getBillerPropertyUnits({ propertyId: property.property_id.toFixed() })
        .then((response) => {
          if (response.data) {
            setUnits(response.data.units)
          }
        })
        .finally(() => {
          setUnitsInitialized(true)
        })
    }
  }, [property.property_id, getBillerPropertyUnits, unitsInitialized])

  const filteredUnits = useMemo(
    () =>
      units?.filter((unit) => {
        const targetString = `${unit.label}${unit.address_line_1}${unit.address_line_2}`.toLowerCase()
        return targetString.includes(query.toLowerCase())
      }),
    [units, query]
  )

  const handleNoneOfTheAbove = () => {
    analytics.logEvent(props.analyticsEventNoneOfTheAboveClicked, { property, query })
    setNotListedModalOpen(true)
    Keyboard.hide()
  }

  const handleCloseNotListedModal = () => {
    setNotListedModalOpen(false)
  }

  const handleStartWithNewAddress = () => {
    analytics.logEvent(props.analyticsEventStartOverClicked, { property, query })
    setNotListedModalOpen(false)
    resetPrefills()
    props.onStartOver()
  }

  const handleContactSupport = () => {
    analytics.logEvent(props.analyticsEventContactSupportClicked, { property, query })
    setNotListedModalOpen(false)
    flexLinks.open(FlexLinks.supportTicketWeb)
  }

  const handleSelect = useCallback(
    (unit: BillerPropertyUnit) => {
      if (checkIsUnitMatched(selectedUnit, unit)) {
        setSelectedUnit(undefined)
        return false
      } else {
        analytics.logEvent(props.analyticsEventUnitSelected, { unit })
        setSelectedUnit(unit)
        return true
      }
    },
    [selectedUnit]
  )

  const onConfirm = useCallback(() => {
    if (selectedUnit) {
      analytics.logEvent(props.analyticsEventUnitConfirmClicked, { unit: selectedUnit })
      setBillerUnit(selectedUnit)
      connectBiller(props.isRelink, { unit: selectedUnit })
    }
  }, [selectedUnit, connectBiller])

  return (
    <>
      <DirectIntegrationUnitSelection
        onSelect={handleSelect}
        onNoneOfTheAbove={handleNoneOfTheAbove}
        onQuery={setQuery}
        query={query}
        property={property}
        units={filteredUnits}
        unitsInitialized={unitsInitialized}
        analyticsScreenName={props.analyticsScreenName}
        selectedUnit={selectedUnit}
        onConfirm={onConfirm}
        connectingBiller={connecting}
        onReset={handleStartWithNewAddress}
        showPrefills={prefillIsActive}
      />
      <DirectIntegrationUnitNotListed
        visible={notListedModalOpen}
        onStartWithNewAddress={handleStartWithNewAddress}
        onContactSupport={handleContactSupport}
        onClose={handleCloseNotListedModal}
      />
    </>
  )
}
